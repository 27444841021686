import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import AxiosApi from "./plugins/axiosApi"

Vue.use(Vuex)


export default new Vuex.Store({
  plugins:[
    createPersistedState()
  ],
  state: {
    barColor: 'rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)',
    /*barImage: 'https://demos.creative-tim.com/material-dashboard/assets/img/sidebar-1.jpg',*/
    barImage: '',
    drawer: null,
    authenticated:false,
    user:{
      id:null,
      username:null,
      name:null,
      roles: Array,
      permisos: Array,
      entidades: Array,
    },
  },
  mutations: {
    SET_BAR_IMAGE (state, payload) {
      state.barImage = payload
    },
    SET_DRAWER (state, payload) {
      state.drawer = payload
    },
    SET_USER (state, payload){
      let userId = payload.tokenParsed.sub;
      state.user.id = userId;
      state.user.username = payload.tokenParsed.preferred_username;
      state.user.name = payload.tokenParsed.given_name +
      " " +
      payload.tokenParsed.family_name;
      const client = process.env.VUE_APP_KEYCLOAK_CLIENTID;
      if(payload.tokenParsed.groups){
        const roleUser = payload.tokenParsed.groups.filter(item => item.substring(item.indexOf(' - ')+3) == client)
        .map(item => item.substring(1, item.indexOf(' - ')));
        state.user.roles = roleUser;
      }
      else{
        state.user.roles = [];
      }
      state.user.permisos = [];
      console.log("state.user.permisos");
      console.log(payload);
      if(payload.resourceAccess){
        for (const key in payload.resourceAccess) {
            if(key == client){
              state.user.permisos = payload.resourceAccess[key].roles;
              break;
            }
        }
      }
      else{
        state.user.permisos = [];
      }
      console.log(state.user.permisos);
      //busco la/s entidad/es
      if(state.user.entidades.length < 1)
      {
          let axiosApi = new AxiosApi(payload.token)
          axiosApi.getByCriteria('usuarios_entidades', userId+'/'+client)
          .then((r) => {
            if(r.data.data){
              let arrayEntidades = r.data.data;
              let entidades = [];
              for (const k in arrayEntidades) {
                //console.log(arrayEntidades[k].entidad_id);
                entidades.push(arrayEntidades[k].entidad_id);

              }
              state.user.entidades = entidades;
            }
            else{
              state.user.entidades = []
            }
            console.log(state.user.entidades);
          })
          .catch(function (error) {
            console.log(error);
          });
      }

    },
    RESET_USER (state){
      state.user.id = null;
      state.user.username = null,
      state.user.name = null;
      state.user.roles =[];
      state.user.permisos = [];
      state.user.entidades = [];
    },
    SET_AUTHENTICATED (state, value) {
      state.authenticated = value
    },
  },
  actions: {
    logout({commit}){
        commit('RESET_USER',{})
        commit('SET_AUTHENTICATED',false)
    },
    login({commit}){
      let client = process.env.VUE_APP_KEYCLOAK_CLIENTID;
      let userId = Vue.prototype.$keycloak.tokenParsed.sub;
      //console.log(Vue.prototype.$keycloak.token);

      commit('SET_USER',Vue.prototype.$keycloak)
    },
  },
  getters : {
    isLogged: state => !!state.user,
    authenticated(state){
        return state.authenticated
    },
  }
})
