export default function permisos({ to, from, next, router, store }) {


  if (store.state.user.permisos.indexOf(to.meta.permiso) === -1) {
      /*Swal.fire({
          icon: "error",
          title: "Acceso Denegado",
          text: "No Autorizado a acceder a esta URL",
      });*/
      router.push({ path: '/unauthorized' });
      return;
  } else {
      next();
  }
}
