  export default {

    install(Vue, options) {
        //evaluar permisos
        Vue.prototype.$can = function (permissionsName) {
          if (typeof this.$store.state.user.permisos !== "undefined"){        
            const permission = permissionsName.some((value) => 
              this.$store.state.user.permisos.includes(value)
            );

            return permission;                        
          }
          
          return false;

        }
        //evaluar roles
        Vue.prototype.$is = function ( roleName) {
          if (typeof this.$store.state.user.roles !== "undefined"){        
            const role = roleName.some((value) => 
              this.$store.state.user.roles.includes(value)
            );

            return role;                        
          }
          
          return false;

        }

    }
  }


