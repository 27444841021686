import Vue from 'vue'
import Router from 'vue-router'
//import NotFound from './views/NotFound'
//import Forbidden from './views/Forbidden'
import Login from './views/Login'
import Logout from './views/Logout'
//import Home from './views/Home'
import store from './store'

import permisosMiddleware from './middleware/permisos';


Vue.use(Router)

let router = new Router({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: () => import('@/views/dashboard/Index'),
      children: [
        // Dashboard
        {
          name: 'dashboard',
          titleApp: 'Dashboard',
          path: '',
          component: () => import('@/views/dashboard/Dashboard'),

          meta: {
            isAuthenticated: true
          },
        },
        // Pages
        {
          name: 'User Profile',
          titleApp: 'Perfil de Usuario',
          path: 'pages/user',
          component: () => import('@/views/dashboard/pages/UserProfile'),

          meta: {
             isAuthenticated: true
          },
        },
        //crud
        {
          name: 'Configuraciones',
          titleApp: 'Configuraciones',
          path: '/admin/configuraciones',
          component: () => import('@/views/cruds/Configuraciones'),

          meta: {
            isAuthenticated: true
         },
        },
        {
          name: 'Auditoria',
          titleApp: 'Auditoria',
          path: '/admin/auditoria',
          component: () => import('@/views/cruds/Auditoria'),

          meta: {
            middleware: [permisosMiddleware],
            store: true,
            permiso:'auditoria_list',
          },
        },
        {
          name: 'Firewall',
          titleApp: 'Firewall',
          path: '/admin/firewall',
          component: () => import('@/views/cruds/Firewall'),

          meta: {
            middleware: [permisosMiddleware],
            store: true,
            permiso:'firewall_list',
          },
        },
        {
          name: 'Roles',
          titleApp: 'Roles',
          path: '/roles/roles',
          /*component: () => import('@/views/cruds/Roles'),*/
          component: () => import('@/views/cruds/RolesEntidades'),
          meta: {
            middleware: [permisosMiddleware],
            store: true,
            permiso:'roles_list',
          },

        },
        {
          name: 'Permisos',
          titleApp: 'Permisos',
          path: '/permisos/permisos',
          component: () => import('@/views/cruds/Permissions'),
          meta: {
            middleware: [permisosMiddleware],
            store: true,
            permiso:'permisos_list',
          },
        },
        {
          name: 'Usuarios',
          titleApp: 'Usuarios',
          path: '/usuarios/usuarios',
          component: () => import('@/views/cruds/Usuarios'),

          meta: {
            middleware: [permisosMiddleware],
            store: true,
            permiso:'usuarios_list',
          },

        },

        {
            name: 'Entidades',
            titleApp: 'Entidades',
            path: '/entidades/entidades',
            component: () => import('@/views/cruds/Entidades'),

            meta: {
              middleware: [permisosMiddleware],
              store: true,
              permiso:'entidades_list',
            },
        },
        {
          name: 'Entidades Roles',
          titleApp: 'Entidades Roles',
          path: '/entidades/entidades_roles',
          component: () => import('@/views/cruds/EntidadesRoles'),

          meta: {
            middleware: [permisosMiddleware],
            store: true,
            permiso:'entidades_roles_list',
          },
        },
        {
          name: 'Tipo Entidad',
          titleApp: 'Tipo Entidad',
          path: '/entidades/tipo_entidad',
          component: () => import('@/views/cruds/TipoEntidad'),

          meta: {
            middleware: [permisosMiddleware],
            store: true,
            permiso:'tipo_entidades_list',
          },
        },

        /*{
          path: '/404',
          name: '404',
          component: NotFound,
        },*/
        /*{
            path: '*',
            redirect: '/404',
        },*/

      ],
    },
    {
      path: '/unauthorized',
      name: 'unauthorized',
      component: () => import('@/views/Unauthorized'),

    },
  ],

})

/*
router.beforeEach((to, from, next) => {
  if(to.meta.middleware=="auth"){
    //console.log(to.meta.middleware);
    //console.log(store.state.authenticated);
    if(store.state.authenticated){
      next()
    }else{
        next({name:"login"})
    }
  }
  else{
    next();
  }*/

  router.beforeEach((to, from, next) => {
    if (to.meta.middleware) {
      const middlewarePromises = to.meta.middleware.map(middleware => {
        return new Promise((resolve, reject) => {
          middleware({ to, from, next: resolve, router, store });
        });
      });

      Promise.all(middlewarePromises)
        .then(() => {
          console.log("Todos los middlewares han sido ejecutados correctamente");
          next();
        })
        .catch(error => {
          console.error("Error al ejecutar los middlewares:", error);
          next(false);
        });
    } else {
      next();
    }
  });




/*router.beforeEach((to, from, next) => {
  const loggedIn = this.$store.state.user

console.log(loggedIn);
  if (to.matched.some(record => record.meta.auth) && !loggedIn) {
    next('/login')
    return
  }
  next()
})*/
/*
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    let token = document.head.querySelector('meta[name="csrf-token"]');
    console.log(token)
    if (token == null) {
      Swal.fire({title:"Sin Autorizacion" ,
      text: "Presione Aceptar para iniciar sesion nuevamente",
      icon: "error",
      showCancelButton: false,
      confirmButtonColor: "#3085d6",
      confirmButtonText: "Aceptar",
      },
    ).then((result) => {
      if (result.isConfirmed) {
        window.location.href = '/login';
      }
    })
      next({
        path: '/login',
        params: { nextUrl: to.fullPath }
      })
    } else {

        next()
      }

  }  else {
    next()
  }
})*/

export default router;
