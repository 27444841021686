export const apiRoute = Object.freeze({

    entidades_roles:"api/entidades/entidades_roles",
    entidadByRole:"api/entidades/entidades_roles/entidadByRole",
    entidades_roles_enable:"api/entidades/entidades_roles/changestatus",
    tipo_entidades:"api/entidades/tipo_entidades",
    tipo_entidades_enable:"api/entidades/tipo_entidades/changestatus",
    entidades:"api/entidades/entidades",
    entidades_enable:"api/entidades/entidades/changestatus",
    usuarios:"api/keycloak/usuarios",
    usuarios_enable:"api/keycloak/usuarios/changestatus",
    usuarios_sessions:"api/keycloak/usuarios/usersessions",
    usuarios_entidades:"api/keycloak/showByUserClient",
    roles:"api/keycloak/roles",
    roles_list:"api/keycloak/roles",
    permissions:"api/keycloak/permissions",
    roles_client:"api/keycloak/roles/client",
    permissions_roles:"api/keycloak/roles/permissions",
    clients:"api/keycloak/clients",
    users: "api/users",
    getUser:"api/getUser",
    userAuthRoute: "api/auth",
    provincias: process.env.VUE_APP_API_URL_PERSONS+"api/provincias",
    identifiertypes: "api/keycloak/persons/identifiertypes/public",

  });
